
























  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { PartnerProviderModel } from '@/store/partner/types';
  import { ContentModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { State } from 'vuex-class';

  @Component({
    name: 'partner-detail',
    components: {
      partnerInfo: () => import('@/components/partner/info.vue'),
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class partnerdetail extends Vue {
    @State('providers', { namespace: 'partner' }) providers!: PartnerProviderModel[];
    @State('page_content') page_content!: ContentModel[];

    assetPath = process.env.VUE_APP_ASSETS_URL;

    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.FAQ)[0]?.image ?? require('@/assets/images/banner/partner.jpg');
    }

    public get selectedPartner() {
      const id = String(this.$route.params.id) || 0;
      return this.providers.filter((v) => v.provider_id === id)[0];
    }

    public created(): void {
      // on componenet created
    }

    public mounted(): void {
      // on componenet mounted
    }
  }
